<template>
  <el-drawer
    title="配置角色"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    width="80%"
  >
    <el-button @click="addRoleButton">新增</el-button>
    <el-table :data="tableData" ref="tableData">
      <el-table-column align="center" label="序号" type="index"></el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small"
          >移除</el-button>
        </template>
      </el-table-column>
      <el-table-column label="角色" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.roleCode" placeholder="请选择">
            <el-option
              v-for="item in roleList"
              :key="item.roleCode"
              :label="item.roleName"
              :value="item.roleCode"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="是否默认" align="center">
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.isDefault"
            :checked="scope.row.isDefault===1? true:false"
            :true-label="1"
            :false-label="2"
            label="是"
            border
            size="medium"
            style="margin:10px 30px  40px 30px"
          ></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div class="dialog-footer">
      <el-button @click="clearn()">取 消</el-button>
      <el-button type="primary" @click="addFormButton()">确 定</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { dataListApi } from "@/api/system/role/role.js";
import { addProductRoeApi } from "@/api/system/product/product.js";
import { getBindRoleByProductIdApi } from "@/api/system/role/role.js";
export default {
  data() {
    return {
      drawer: false,
      uniqueCode: "",
      direction: "rtl",
      tableData: [],
      roleList: []
    };
  },
  methods: {
    show(uniqueCode,productCode) {
      this.drawer = true;
      this.uniqueCode = uniqueCode;
      this.roleDataListButton(productCode);
      this.getBindRoleByProductIdButton();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.clearn();
        })
        .catch(_ => {});
    },
    clearn() {
      this.drawer = false;
      this.tableData = [];
      this.$parent.selectDataListButton();
    },

    addFormButton() {
      //   //传入的数据
      var addRoleFrom = {
        uniqueCode: this.uniqueCode,
        roleList: this.tableData
      };
      addProductRoeApi(addRoleFrom).then(res => {
        this.clearn();
      });
    },
    addRoleButton() {
      var roleFrom = {
        isDefault: 2
      };
      this.tableData.push(roleFrom);
    },
    roleDataListButton(productCode) {
      dataListApi({productCode:productCode}).then(res => {
        this.roleList = res.result;
      });
    },
    getBindRoleByProductIdButton() {
      getBindRoleByProductIdApi({ uniqueCode: this.uniqueCode }).then(res => {
        this.tableData = res.result;
      });
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    }
  }
};
</script>
<style scoped>
</style>